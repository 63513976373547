export const getYoutubeVideoId = (url: string) => {
    var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[1].length===11)? match[1] : false;
}

export const wrapDOMElement = (el: HTMLElement, wrapper: HTMLElement) => {
    el.parentNode!.insertBefore(wrapper, el);
    wrapper.appendChild(el);
}

export const allowedOembedProviders = ['youtube.com', 'youtu.be']

// oembed providers are expensive.. This parses the story content for oembed links and embeds youtube links. Removes all other oembeds.
export const activateYoutubeEmbeds = () => {
    document.querySelectorAll('oembed[url]').forEach((element) => {
        const url = element.getAttribute('url')
        if (!url || !allowedOembedProviders.some((provider) => url.includes(provider))) return element.remove()
        // Construct the iframe
        const iframe = document.createElement('iframe')
        iframe.setAttribute('src', `https://www.youtube-nocookie.com/embed/${getYoutubeVideoId(url)}`)
        iframe.setAttribute('frameborder', '0')
        iframe.setAttribute('allowfullscreen', 'true')
        iframe.setAttribute('style', 'position:absolute;top:0;left:0;width:100%;height:100%;')
        // Insert it into the DOM
        element.parentNode!.insertBefore(iframe, null)
        // Wrap it into some more styling
        const wrapper = document.createElement('div')
        wrapper.setAttribute('style', 'position:relative;padding-top:56.25%;')
        wrapDOMElement(iframe, wrapper)
        // Remove the original oembed
        element.remove()
    })
}