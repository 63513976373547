import { Room, RoomOutlined } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Post } from '../models/post'
import { Project } from '../models/project'
import { Role } from '../models/user'

interface Props {
	post: Post
}

const PostPin: React.FC<Props> = ({ post }) => {
	const dispatch = useDispatch<Dispatch>()
	const { isPinned, isStarkmacher, isProjectPage, projectHasPin } = useSelector((state: GlobalState) => {
		const projectId = typeof post.project === 'string' ? post.project : post.project?._id
		const project = state.project.projects.find((p) => p._id === projectId)
		return {
			isPinned: project?.pinnedPost && project?.pinnedPost === post._id,
			isStarkmacher: state.user.currentUser?.role === Role.starkmacher || state.user.currentUser?.role === Role.admin,
			isProjectPage: state.environment.currentRoute?.path.includes('/projects/'),
			projectHasPin: project?.pinnedPost
		}
	})

	if (!isProjectPage || !(post.project as Project)?._id) return null

	return (
		<>
			{isPinned ? (
				<Room
					color='primary'
					style={{ marginRight: 8, cursor: isStarkmacher ? 'pointer' : 'normal' }}
					onClick={() =>
						(post.project as Project)._id &&
						isStarkmacher &&
						dispatch.project.unpinPost({ projectId: (post.project as Project)._id, postId: post._id })
					}
				/>
			) : (
				!projectHasPin &&
				isStarkmacher && (
					<RoomOutlined
						color='primary'
						style={{ marginRight: 8, cursor: isStarkmacher ? 'pointer' : 'normal' }}
						onClick={() =>
							(post.project as Project)._id &&
							isStarkmacher &&
							dispatch.project.pinPost({ projectId: (post.project as Project)._id, postId: post._id })
						}
					/>
				)
			)}
		</>
	)
}

export default PostPin
