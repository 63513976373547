import { unstable_createMuiStrictModeTheme } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

interface CreateThemeOptions {
  primaryColor?: string;
  secondaryColor?: string;
}

export const defaultPrimaryColor = '#6eb0fc' // '#f2cd35'
export const defaultSecondaryColor = '#08c888' // '#f1a34f'

const createMuiThemeHelper = process.env.NODE_ENV === 'production' ? createMuiTheme : unstable_createMuiStrictModeTheme;

const createTheme = (options?: CreateThemeOptions) => {
  return createMuiThemeHelper({
    palette: {
      primary: {
        main: defaultPrimaryColor,
      },
      secondary: {
        main: defaultSecondaryColor,
      },
    },
    typography: {
      fontFamily: [
        'Oxygen Mono',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 11
    },
    props: {
      MuiInput: {
        inputProps: {
          spellCheck: false
        }
      },
      MuiInputBase: {
        autoComplete: 'off',
        inputProps: {
          autoComplete: 'off'
        }
      },
      MuiTextField: {
        autoComplete: 'off',
        inputProps: {
          spellCheck: false
        }
      }
    }
  })
};

export default createTheme;