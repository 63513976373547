import { createModel } from '@rematch/core';
import axios from 'axios';
import { RootModel } from '.';
import { debounce } from '../helpers/debounce';
import i18n from '../i18n';
import ProjectService from '../services/ProjectService';
import RouteService, { Routes } from '../services/RouteService';
import UserService, { ChangePasswordPayload, RecoverAccountPayload, TokenSuccessResponse } from '../services/UserService';
import { Dispatch, GlobalState } from './bootstrap';

export enum Role {
    user = 'user',
    starkmacher = 'starkmacher',
    admin = 'admin'
}

export interface User extends UserData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface FullUser extends User {

}

export interface RegisterUserData {
    firstName: string;
    lastName: string;
    password: string;
    inviteHash: string;
    email: string;
}

export interface UserData {
    tenant: string;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
    avatar?: string;
    projects: string[];
}

type UserState = {
    currentUser: User | null;
    accessToken: string | null;
    users: Array<User>;
    currentUserLoading: boolean;
    usersById: {
        [userId: string]: User
    }
    usersByProjectId: {
        [projectId: string]: User[]
    }
}

const userService = new UserService();
const projectService = new ProjectService();

const initialState = {
    currentUser: null,
    accessToken: null,
    users: [],
    currentUserLoading: true,
    usersById: {},
    usersByProjectId: {}
} as UserState

export const user = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setAccessToken: (state: UserState, accessToken: string) => ({
            ...state,
            accessToken
        }),
        setCurrentUser: (state: UserState, currentUser: User) => ({
            ...state,
            currentUser
        }),
        setUsers: (state: UserState, users: Array<User>) => ({
            ...state,
            users
        }),
        setCurrentUserLoading: (state: UserState, currentUserLoading: boolean) => ({
            ...state,
            currentUserLoading
        }),
        setAvatarUrl: (state: UserState, avatarUrl: string) => ({
            ...state,
            currentUser: {
                ...state.currentUser!,
                avatar: avatarUrl
            }
        }),
        setUsersById: (state: UserState, user: User) => ({
            ...state,
            usersById: {
                ...state.usersById,
                [user._id]: user
            }
        }),
        setUsersByProjectId: (state: UserState, payload: { projectId: string, users: User[] }) => ({
            ...state,
            usersByProjectId: {
                ...state.usersByProjectId,
                [payload.projectId]: payload.users
            }
        })
    },
    effects: (dispatch: Dispatch) => ({
        afterLoginSuccess: async (payload: { user: User, accessToken: string}, state: GlobalState): Promise<void> => {
            axios.defaults.headers.common['x-kukido-tenant-id'] = payload.user.tenant
            dispatch.user.setCurrentUser(payload.user)
            dispatch.user.setAccessToken(payload.accessToken)
            dispatch.notification.getMine()
            dispatch.project.init()
        },
        create: async (userData: UserData, state: GlobalState): Promise<void> => {
            await userService.create(userData)
        },
        getAllUsers: async (payload: void, state: GlobalState): Promise<void> => {
            const users = await userService.getAll()
            dispatch.user.setUsers(users)
        },
        getCurrentUser: async (_id: string, state: GlobalState): Promise<void> => {
            dispatch.user.setCurrentUserLoading(true)
            const user = await userService.getById(_id)
            dispatch.user.setCurrentUserLoading(false)
            dispatch.user.setCurrentUser(user)
            axios.defaults.headers.common['x-kukido-tenant-id'] = user.tenant
        },
        searchForUsers: async (searchTerm: string, state: GlobalState): Promise<{ id: string, userId: string }[]> => {
            const debouncePromise = debounce(async () => await userService.search(searchTerm), 500)
            const users = await debouncePromise()
            return users.map(u => ({
                id: `@${u.firstName} ${u.lastName}`,
                userId: u._id,
            }))
        },
        login: async (payload: { email: string, password: string }, state: GlobalState): Promise<void> => {
            try {
                const response = await userService.login(payload.email, payload.password)
                dispatch.environment.enqueueSnack({message: i18n.t('user.welcomeBack', { name: response.user.firstName }), options: { variant: 'success' }})
                dispatch.user.afterLoginSuccess({ user: response.user, accessToken: response.accessToken })
                dispatch.environment.navigate(RouteService.getByPath(Routes.home))
            } catch(e) {
                dispatch.environment.enqueueSnack({message: i18n.t('user.loginFailed'), options: { variant: 'error' }})
            }
        },
        getNewAccessToken: async (payload: void, state: GlobalState): Promise<boolean> => {
            // We need to return if we have to select a tenant, otherwise the init logic cant know if and where to navigate to
            try {
                const response = await userService.getNewAccessToken()
                const { accessToken, user } = response as TokenSuccessResponse
                dispatch.user.afterLoginSuccess({ user, accessToken })
                // dispatch.environment.navigate(RouteService.getByPath(Routes.home))
                return false;
            } catch(e) {
                if (!RouteService.isOnAnonymousPage()) {
                    dispatch.environment.navigate(RouteService.getByPath(Routes.login))
                }
            }

            return false;
        },
        logout: async (payload: void, state: GlobalState): Promise<void> => {
            localStorage.removeItem('refreshToken')
            dispatch.user.reset()
            dispatch.post.reset()
            dispatch.project.reset()
            dispatch.notification.reset()
            dispatch.environment.navigate(RouteService.getByPath(Routes.login))
        },
        deleteAccount: async (payload: void, state: GlobalState): Promise<void> => {
            if (!state.user.currentUser) return

            try {
                await userService.delete(state.user.currentUser?._id)
                dispatch.user.logout()
                dispatch.environment.enqueueSnack({ message: i18n.t('user.deleteAccountSuccess')});
            } catch (e) {
                dispatch.environment.enqueueSnack({ message: i18n.t('user.deleteAccountError'), options: { variant: 'error' }});
            }
            
        },
        update: async (payload: User, state: GlobalState): Promise<void> => {
            const updatedUser = await userService.update(payload);

            updatedUser && dispatch.user.setCurrentUser(updatedUser);
        },
        uploadAvatar: async (payload: { avatar: Blob, name: string } , state: GlobalState): Promise<void> => {
            const avatarUrl = await userService.uploadAvatar(payload.avatar, payload.name)
            
            avatarUrl && dispatch.user.setAvatarUrl(avatarUrl)
        },
        invite: async (payload: { email: string, asStarkmacher: boolean }, state: GlobalState): Promise<void> => {
            try {
                await userService.invite(payload.email, payload.asStarkmacher);
                dispatch.environment.enqueueSnack({ message: i18n.t('starkmacher.invitationSent')});
            } catch(e) {
                dispatch.environment.enqueueSnack({ message: i18n.t('starkmacher.invitationFailed'), options: { variant: 'error' }});
            }
        },
        register: async (payload: RegisterUserData, state: GlobalState): Promise<void> => {
            try {
                const response = await userService.register(payload)
                const { accessToken, user } = response as TokenSuccessResponse
                dispatch.environment.enqueueSnack({ message: i18n.t('user.welcome', { name: payload.firstName })})
                dispatch.user.afterLoginSuccess({ user, accessToken })
                dispatch.environment.navigate(RouteService.getByPath(Routes.home))
            } catch(e) {
                dispatch.environment.enqueueSnack({ message: i18n.t('user.registrationFailed'), options: { variant: 'error' }});

            }
        },
        getUserById: async (userId: string, state: GlobalState): Promise<void> => {
            const user = await userService.getById(userId)

            dispatch.user.setUsersById(user)
        },
        changePassword: async (payload: ChangePasswordPayload, state: GlobalState): Promise<boolean> => {
            try {
                await userService.changePassword(payload)

                dispatch.environment.enqueueSnack({ message: i18n.t('user.passwordChanged'), options: { variant: 'success' }})
                return true;
            } catch(e) {
                dispatch.environment.enqueueSnack({ message: i18n.t('user.passwordChangeFailed'), options: { variant: 'error' }})
                return false;
            }
        },
        sendRecovery: async (email: string, state: GlobalState): Promise<void> => {
            try {
                await userService.sendRecovery(email)

                dispatch.environment.enqueueSnack({ message: i18n.t('user.recoveryMailSent'), options: { variant: 'success' }})
            } catch(e) {
                dispatch.environment.enqueueSnack({ message: i18n.t('user.recoveryMailFailed'), options: { variant: 'error' }})
            }
        },
        recoverAccount: async (payload: RecoverAccountPayload, state: GlobalState): Promise<void> => {
            try {
                await userService.recoverAccount(payload)

                dispatch.environment.enqueueSnack({ message: i18n.t('user.recoverySuccess'), options: { variant: 'success' }})
                setTimeout(() => {
                    dispatch.environment.navigate(RouteService.getByPath(Routes.login))
                }, 3000)
            } catch(e) {
                dispatch.environment.enqueueSnack({ message: i18n.t('user.recoveryFailed'), options: { variant: 'error' }})
            }
        },
        unlockUserForProject: async (payload: { userId: string; projectId: string }, state: GlobalState): Promise<void> => {
            const updatedUser = await userService.unlockUserForProject(payload);
            dispatch.user.setUsers(state.user.users.map(u => u._id === payload.userId ? updatedUser: u))
            dispatch.user.setUsersById(updatedUser)
        },
        lockUserForProject: async (payload: { userId: string; projectId: string }, state: GlobalState): Promise<void> => {
            const updatedUser = await userService.lockUserForProject(payload);
            dispatch.user.setUsers(state.user.users.map(u => u._id === payload.userId ? updatedUser: u))
            dispatch.user.setUsersById(updatedUser)
        },
        getUsersByProjectId: async (projectId: string, state: GlobalState): Promise<void> => {
            const users = await projectService.getUsers(projectId);
            dispatch.user.setUsersByProjectId({ projectId, users })
        },
    })
});