import axios from 'axios';
import { Post, PostData } from '../models/post';

class PostService {
    async create(postData: PostData): Promise<string> {
        const response = await axios.post<Post>('/post/create', { ...postData });
        return response.data._id;
    }

    async getById(_id: string): Promise<Post> {
        const response = await axios.get<{ post: Post }>(`/post/${_id}`);
        return response.data.post;
    }

    async getByProjectId(payload: { projectId: string, start?: number, limit?: number }): Promise<Array<Post>> {
        let route = `/post/byProjectId/${payload.projectId}`
        route += `?start=${payload.start ?? 0}`
        if (payload.limit) route += `&limit=${payload.limit}`
        const response = await axios.get<{ posts: Array<Post>; start: number; limit: number; }>(route);
        return response.data.posts;
    }

    async getMainFeed(payload: { start: number, limit?: number }): Promise<Array<Post>> {
        let route = `/post/mainFeed`
        route += `?start=${payload.start ?? 0}`
        if (payload.limit) route += `&limit=${payload.limit}`
        const response = await axios.get<{ posts: Array<Post>; start: number; limit: number; }>(route);
        return response.data.posts;
    }

    async update(postData: Post): Promise<Post> {
        const response = await axios.put<{ updatedPost: Post }>(`/post/${postData._id}`, postData);
        return response.data.updatedPost;
    }

    async delete(id: string): Promise<boolean> {
        const response = await axios.delete<{ postDeleted: boolean }>(`/post/${id}`);
        return response.data.postDeleted;
    }

    async like(postId: string): Promise<Post> {
        const response = await axios.get<{ updatedPost: Post }>(`/post/like/${postId}`);
        return response.data.updatedPost;
    }

    async unlike(postId: string): Promise<Post> {
        const response = await axios.get<{ updatedPost: Post }>(`/post/unlike/${postId}`);
        return response.data.updatedPost;
    }

    async comment(postId: string, body: string): Promise<Post> {
        const response = await axios.post<{ updatedPost: Post }>(`/post/comment/${postId}`, { body });
        return response.data.updatedPost;
    }

    async deleteComment(postId: string, commentId: string): Promise<Post> {
        const response = await axios.delete<{ updatedPost: Post }>(`/post/comment/${postId}/${commentId}`);
        return response.data.updatedPost;
    }
}

// interface PagedPosts {
//     posts: Post[];
//     start: number;
//     limit: number;
// }

export default PostService;