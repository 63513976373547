import axios from 'axios';

class FileService {
    async upload(file: File): Promise<string> {
        const fd = new FormData()
        fd.append('upload', file, file.name)
        const response = await axios.post<{ url: string }>('/proxy/uploadMedia', fd, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response.data.url;
    }
}

export default FileService