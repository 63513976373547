export interface StaticTag {
    name: string;
    color: string;
    id: number;
}

export const StaticTags: StaticTag[] = [
    {
        id: 1,
        name: 'Medienkompetenz',
        color: '#e5233d'
    },
    {
        id: 2,
        name: 'Förderung',
        color: '#dda73a'
    },
    {
        id: 3,
        name: 'Lebenswelt',
        color: '#4ca146'
    },
    {
        id: 4,
        name: 'Workshop',
        color: '#c5192d'
    },
    {
        id: 5,
        name: 'Kooperation',
        color: '#ef402c'
    },
    {
        id: 6,
        name: 'Infrastruktur',
        color: '#27bfe6'
    },
    {
        id: 7,
        name: 'BestPractice',
        color: '#fbc412'
    },
    {
        id: 8,
        name: 'Praxis',
        color: '#a31c44'
    },
    {
        id: 9,
        name: 'Angebot',
        color: '#f26a2d'
    },
    {
        id: 10,
        name: 'Gaming',
        color: '#e01483'
    },
    {
        id: 11,
        name: 'Coding',
        color: '#f89d2a'
    },
    {
        id: 12,
        name: 'Making',
        color: '#bf8d2c'
    },
    {
        id: 13,
        name: 'Inklusion',
        color: '#407f46'
    },
    {
        id: 14,
        name: 'EDV',
        color: '#1f97d4'
    },
    {
        id: 15,
        name: 'Digitalität',
        color: '#59ba48'
    },
    {
        id: 16,
        name: 'Querschnittsthema',
        color: '#126a9f'
    },
    {
        id: 17,
        name: 'Gender',
        color: '#13496b'
    },
    {
        id: 18,
        name: 'Barrierefreiheit',
        color: '#407f46'
    },
    {
        id: 19,
        name: 'Förderprogramm',
        color: '#dda73a'
    },
    {
        id: 20,
        name: 'Medienbildung',
        color: '#e5233d'
    },
    {
        id: 21,
        name: 'Medienerziehung',
        color: '#e5233d'
    },
    {
        id: 22,
        name: 'Mediengestaltung',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Medienkritik',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Mediennutzung',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Medienkunde',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Medienpädagogik',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Medienkonzept',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Medienschutz',
        color: '#e5233d'
    },
    {
        id: 23,
        name: 'Mediendidaktik',
        color: '#e5233d'
    }
]