import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { mergeAndSort } from '../helpers/mergeAndSort';
import i18n from '../i18n';
import PostService from '../services/PostService';
import { Dispatch, GlobalState } from './bootstrap';
import { KukidoAttachment, Project } from './project';
import { User } from './user';

export interface PostComment extends PostCommentData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

interface PostCommentData {
    body: string;
    user: string | User;
}

export interface Post extends PostData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface PostData {
    title: string;
    body: string;
    project: string | Project | null;
    user: string | User;
    tags?: string[];
    likes?: string[]; // UserIds
    comments?: string[] | PostComment[];
    files?: KukidoAttachment[];
}

type PostState = {
    currentPost: Post | null,
    postsByProjectId: { [projectId: string]: Post[] },
    projectByIdHasMore: { [projectId: string]: boolean },
    mainFeed: Post[],
    mainFeedHasMore: boolean,
    postsById: { [postId: string]: Post } // Only for misc things like notifications
}

const postService = new PostService();

const initialState = {
    currentPost: null,
    postsByProjectId: {},
    projectByIdHasMore: {},
    mainFeed: [],
    mainFeedHasMore: false,
    postsById: {},
} as PostState

// This custom reducer takes the state, an updatedPost (API response after update call) and the key which should be updated (e.g. likes -> Take likes of updatedPost and replace them in the state)
const postSubEntityReducer = (state: PostState, updatedPost: Post, entityKey: keyof PostData) => {
    const newFeed = state.mainFeed?.map(s => s._id === updatedPost._id ? {
        ...s,
        [entityKey]: updatedPost[entityKey]
    } : s) ?? []
    const projectId = typeof updatedPost.project === 'string' ? updatedPost.project : updatedPost.project?._id
    const projectPosts = state.postsByProjectId[projectId ?? '']
    const postById = state.postsById[updatedPost._id]

    let newState = {
        ...state,
        mainFeed: newFeed
    }

    if (projectPosts) {
        newState.postsByProjectId = {
            ...state.postsByProjectId,
            [projectId!]: projectPosts.map(s => s._id === updatedPost._id ? {
                ...s,
                [entityKey]: updatedPost[entityKey]
            } : s)
        }
    }

    if (postById) {
        newState.postsById = {
            ...state.postsById,
            [postById._id]: {
                ...postById,
                [entityKey]: updatedPost[entityKey]
            }
        }
    }

    return newState
}

export const post = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setPostsByProjectId: (state: PostState, payload: {projectId: string, posts: Post[]}) => ({ 
            ...state,
            postsByProjectId: {
                ...state.postsByProjectId,
                [payload.projectId]: mergeAndSort(state.postsByProjectId[payload.projectId], payload.posts)
            },
        }),
        setProjectByIdHasMore: (state: PostState, payload: {projectId: string, hasMore: boolean}) => ({
            ...state,
            projectByIdHasMore: {
                ...state.projectByIdHasMore,
                [payload.projectId]: payload.hasMore
            }
        }),
        setMainFeed: (state: PostState, payload: { posts: Post[], reset?: boolean }) => ({
            ...state,
            mainFeed: payload.reset ? mergeAndSort([], payload.posts) : mergeAndSort(state.mainFeed, payload.posts)
        }), 
        setMainFeedHasMore: (state: PostState, hasMore: boolean) => ({ ...state, mainFeedHasMore: hasMore }),
        setCurrentPost: (state: PostState, post: Post | null) => ({ ...state, currentPost: post }),
        setPostById: (state: PostState, post: Post) => ({ 
            ...state,
            postsById: {
                ...state.postsById,
                [post._id]: post
            }
        }),
        setPostLikes: (state: PostState, updatedPost: Post) => postSubEntityReducer(state, updatedPost, 'likes'),
        setPostComments: (state: PostState, updatedPost: Post) => postSubEntityReducer(state, updatedPost, 'comments'),
    },
    effects: (dispatch: Dispatch) => ({
        createPost: async (postData: Omit<PostData, 'user'>, state: GlobalState): Promise<void> => {
            try {
                if (!state.user.currentUser) return
                await postService.create({ ...postData, user: state.user.currentUser?._id })
                dispatch.environment.enqueueSnack({message: i18n.t('post.created'), options: { variant: 'success' }})
                const projectId = typeof postData.project === 'string' ? postData.project : postData.project?._id
                projectId ? dispatch.post.getPostsByProjectId({ projectId: projectId, fromStart: true }) : dispatch.post.getMainFeed({ fromStart: true })
            } catch(e) {
                dispatch.environment.enqueueSnack({message: i18n.t('post.creationFailed'), options: { variant: 'error' }})
            }
        },
        getPostsByProjectId: async (payload: { projectId: string; limit?: number, fromStart?: boolean }, state: GlobalState): Promise<void> => {
            const limit = payload.limit ?? 10;
            const projectId = payload.projectId
            if (!projectId) return
            const posts = await postService.getByProjectId({ projectId, start: payload.fromStart ? 0 : state.post.postsByProjectId[payload.projectId]?.length ?? 0 })
            const hasMore = !(posts?.length < limit)
            dispatch.post.setPostsByProjectId({ projectId, posts })
            dispatch.post.setProjectByIdHasMore({ projectId, hasMore })
        },
        getMainFeed: async (payload: { limit?: number, fromStart?: boolean }, state: GlobalState): Promise<void> => {
            const limit = payload.limit ?? 10;
            const posts = await postService.getMainFeed({ start: payload.fromStart ? 0 : state.post.mainFeed.length })
            const hasMore = !(posts?.length < limit)
            dispatch.post.setMainFeed({ posts, reset: payload.fromStart })
            dispatch.post.setMainFeedHasMore(hasMore)
        },
        getPost: async (_id: string, state: GlobalState): Promise<void> => {
            const post = await postService.getById(_id)
            dispatch.post.setPostById(post)
        },
        updatePost: async (postData: Post, state: GlobalState): Promise<void> => {
            const projectId = typeof postData.project === 'string' ? postData.project : postData.project?._id
            const updatedPost = await postService.update(postData)
            projectId && dispatch.post.setPostsByProjectId({
                projectId: projectId,
                posts: state.post.postsByProjectId[projectId].map((p: Post) => p._id === updatedPost._id ? updatedPost : p)
            })
            dispatch.post.setMainFeed({ posts: state.post.mainFeed.map(p => p._id === updatedPost._id ? updatedPost : p) })
        },
        deletePost: async (payload: { postId: string, projectId?: string }, state: GlobalState): Promise<void> => {
            try {
                await postService.delete(payload.postId)
                dispatch.post.setCurrentPost(null)
                payload.projectId && dispatch.post.setPostsByProjectId({
                    projectId: payload.projectId,
                    posts: state.post.postsByProjectId[payload.projectId].filter(p => p._id !== payload.postId)
                })
                dispatch.environment.enqueueSnack({message: i18n.t('post.deleted'), options: { variant: 'success' }})
            } catch (e) {
                dispatch.environment.enqueueSnack({message: i18n.t('post.deletionFailed'), options: { variant: 'error' }})
            } finally {
                dispatch.dialog.closeDialog()
            }
        },
        likePost: async (postId: string, state: GlobalState): Promise<void> => {
            const updatedPost = await postService.like(postId)
            dispatch.post.setPostLikes(updatedPost)
        },
        unlikePost: async (postId: string, state: GlobalState): Promise<void> => {
            const updatedPost = await postService.unlike(postId)
            dispatch.post.setPostLikes(updatedPost)
        },
        commentPost: async (payload: { postId: string, body: string }, state: GlobalState): Promise<void> => {
            const updatedPost = await postService.comment(payload.postId, payload.body)
            dispatch.post.setPostComments(updatedPost)
        },
        deletePostComment: async (payload: { postId: string, commentId: string }, state: GlobalState): Promise<void> => {
            const updatedPost = await postService.deleteComment(payload.postId, payload.commentId)
            dispatch.post.setPostComments(updatedPost)
            dispatch.environment.enqueueSnack({ message: i18n.t('post.deleteCommentSuccess'), options: { variant: 'success' }})
        },
    })
});