import { Button, Divider, FormControl, lighten, makeStyles, Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import React, { CSSProperties, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Mention, MentionsInput } from 'react-mentions'
import { useDispatch, useSelector } from 'react-redux'
import TimeAgo from 'timeago-react'
import { debounce } from '../helpers/debounce'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Post, PostComment } from '../models/post'
import UserService from '../services/UserService'
import KukidoAvatar from './Avatar'
import './mentions.css'

const useStyles = makeStyles((theme) => ({
	userName: {
		paddingLeft: theme.spacing(1)
	},
	commentsContainer: {
		backgroundColor: lighten(theme.palette.primary.main, 0.9)
	},
	commentsInnerContainer: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`
	},
	comment: {
		marginBottom: theme.spacing(2)
	},
	commentHeader: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	commentBody: {
		marginTop: theme.spacing(1),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap'
	},
	commentDivider: {
		margin: `${theme.spacing(1)}px 0`
	},
	viewAllComments: {
		marginLeft: theme.spacing(3)
	},
	deleteComment: {
		padding: 0
	},
	writeCommentMode: {
		paddingBottom: theme.spacing(1)
	},
	commentsList: {
		paddingTop: theme.spacing(1)
	},
	mentions: {}
}))

interface Props {
	post: Post
	showAll?: boolean
}

const mentionInputClassName = 'kukido-mentions-input'
const floatingLabelClassName = 'kukido-mentions-input-floating-label'
const paddingTop = '21px'
const paddingBottom = '3px'
const padding = `${paddingTop} 12px ${paddingBottom} 12px`
const fontSize = 16
const mentionBackgroundColor = '#9900301a'
const mentionColor = '#990030'

const getPlaceholderStyle = (isSuperscript: boolean): CSSProperties => ({
	top: isSuperscript ? 6 : 15,
	fontSize: isSuperscript ? 0.59 * fontSize : 0.8 * fontSize,
	left: 12
})
export const useStylesMentionInput = (value: string) =>
	makeStyles((theme) => ({
		root: {
			width: '100%',

			[`& .${mentionInputClassName}__control`]: {
				fontSize: theme.typography.body1.fontSize,
				backgroundColor: theme.palette.action.disabledBackground,
				borderTopRightRadius: 4,
				borderTopLeftRadius: 4
			},

			[`& .${mentionInputClassName}__input`]: {
				padding,
				border: 0,
				margin: '0 !important',
				borderBottom: `1px solid ${theme.palette.divider}`,
				color: theme.palette.text.primary,
				transition: theme.transitions.create('border-bottom-color', {
					duration: theme.transitions.duration.shorter,
					easing: theme.transitions.easing.easeInOut
				}),
				'&:focus': {
					outline: 'none',
					borderBottom: `2px solid ${theme.palette.primary.main}`
				},
				'&:disabled': {
					color: theme.palette.text.disabled,
					borderBottom: `1px dotted ${theme.palette.divider}`
				},
				'&:hover:not(:disabled):not(:focus)': {
					borderBottom: `2px solid ${theme.palette.divider}`
				}
			},

			[`& .${mentionInputClassName}__highlighter`]: {
				padding
			},

			[`& .${floatingLabelClassName}`]: {
				...getPlaceholderStyle(Boolean(value)),
				color: theme.palette.text.secondary,
				fontWeight: 'normal',
				position: 'absolute',
				pointerEvents: 'none',
				transition: theme.transitions.create('all', {
					duration: theme.transitions.duration.shorter,
					easing: theme.transitions.easing.easeInOut
				})
			},

			'&:focus-within': {
				[`& .${floatingLabelClassName}`]: {
					...getPlaceholderStyle(true),
					color: theme.palette.primary.main
				}
			},

			[`& .${mentionInputClassName}__suggestions`]: {
				backgroundColor: theme.palette.background.paper,
				marginTop: `calc(${paddingTop} + ${paddingBottom}) !important`,
				border: `1px solid ${theme.palette.action.disabledBackground}`,
				boxShadow: `0 0 8px ${theme.palette.action.disabled}`,
				width: 'max-content',
				maxHeight: 300,
				overflow: 'auto'
			},

			[`& .${mentionInputClassName}__suggestions__item`]: {
				display: 'flex',
				alignItems: 'center',
				padding: theme.spacing(1),
				transition: theme.transitions.create('background-color', {
					duration: theme.transitions.duration.shortest,
					easing: theme.transitions.easing.easeInOut
				})
			},

			[`& .${mentionInputClassName}__suggestions__item--focused`]: {
				backgroundColor: mentionBackgroundColor,
				color: mentionColor
			}
		}
	}))

const prepareComment = (value: string, mentions: Array<{ display: string; id: string }>): string => {
	let newValue = value
	mentions.forEach((m) => {
		newValue = newValue.replace(new RegExp(`${m.display}`, 'gi'), `<span class="mention" data-mention="${m.display}">${m.display}</span>`)
	})
	return newValue
}

const PostComments: React.FC<Props> = (props) => {
	const classes = useStyles()
	const { post, showAll = false } = props
	const [localShowAll, setLocalShowAll] = useState<boolean>(showAll)
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const [writeComment, setWriteComment] = useState<string>('')
	const [writeCommentValue, setWriteCommentValue] = useState<string>('')
	const [writeCommentMode, setWriteCommentMode] = useState<boolean>(false)
	const needsShowMoreIndicator = !localShowAll && post.comments && post.comments.length > 2
	const { currentUser } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser
	}))
	const mentionClasses = useStylesMentionInput(writeComment)()
	const [mentions, setMentions] = useState([])

	const handleCreateComment = async () => {
		await dispatch.post.commentPost({ postId: post._id, body: prepareComment(writeCommentValue, mentions) })
		setWriteComment('')
		setWriteCommentValue('')
		setMentions([])
		setWriteCommentMode(false)
	}

	const onChange = (e: any, newValue: any, newPlainTextValue: any, mentions: any) => {
		setMentions(mentions)
		setWriteComment(e.target.value)
		setWriteCommentValue(newPlainTextValue)
	}

	return (
		<div className={classes.commentsContainer}>
			<div className={classes.commentsInnerContainer}>
				{post.comments && post.comments.length > 0 && (
					<div className={classes.commentsList}>
						{post.comments.map((c: PostComment | string, index: number) => {
							if (typeof c === 'string' || typeof c.user === 'string') return null
							if (!localShowAll && index <= post.comments!.length - 3) return null
							const isOwnComment = typeof c.user === 'string' ? c.user === currentUser?._id : c.user?._id === currentUser?._id

							return (
								<div className={classes.comment} key={c._id}>
									<div className={classes.commentHeader}>
										<KukidoAvatar user={c.user} showName />
										<Typography variant='caption' className={classes.userName}>
											<TimeAgo datetime={c.createdAt} locale={i18n.language} />
										</Typography>
									</div>
									<Typography variant='body2' className={classes.commentBody}>
										<span>{parse(c.body)} </span>
										{isOwnComment && (
											<Button
												className={classes.deleteComment}
												variant='text'
												color='secondary'
												onClick={() => dispatch.post.deletePostComment({ postId: post._id, commentId: c._id })}>
												{t('post.deleteComment')}
											</Button>
										)}
									</Typography>
									{index !== post.comments!.length - 1 && <Divider className={classes.commentDivider} />}
								</div>
							)
						})}
					</div>
				)}
				{writeCommentMode ? (
					<div className={classes.writeCommentMode}>
						<FormControl style={{ marginBottom: 12 }} className={mentionClasses.root}>
							<label className={floatingLabelClassName}>{t('post.writeacomment')}</label>
							<MentionsInput value={writeComment} onChange={onChange} className='kukido-mentions-input' autoFocus>
								<Mention
									trigger='@'
									style={{ backgroundColor: mentionBackgroundColor, color: mentionColor, fontWeight: 'inherit' }}
									data={async (term, callback) => {
										try {
											const userService = new UserService()
											const debouncePromise = debounce(async () => await userService.search(term), 500)
											const users = await debouncePromise()
											const items = users.map((u) => ({
												display: `@${u.firstName} ${u.lastName}`,
												id: u._id
											}))
											callback(items)
										} catch (e) {
											console.error(e)
										}
									}}
								/>
							</MentionsInput>
						</FormControl>
						<Button variant='contained' color='primary' onClick={handleCreateComment}>
							{t('basic.submit')}
						</Button>
						<Button
							variant='text'
							onClick={() => {
								setWriteCommentMode(false)
								setWriteComment('')
								setWriteCommentValue('')
							}}>
							{t('basic.cancel')}
						</Button>
					</div>
				) : (
					<div style={{ display: 'flex' }}>
						<Button
							variant='outlined'
							color='secondary'
							onClick={() => {
								setWriteCommentMode(true)
								setWriteComment('')
								setWriteCommentValue('')
							}}>
							{t('post.writeacomment')}
						</Button>
						{needsShowMoreIndicator && (
							<Button className={classes.viewAllComments} variant='outlined' color='secondary' onClick={() => setLocalShowAll(true)}>
								{t('post.viewMoreComments')}
							</Button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

export default PostComments
