import { Button, Card, CardActions, CardContent, makeStyles, Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TimeAgo from 'timeago-react'
import { activateYoutubeEmbeds } from '../helpers/embed'
import { StaticTags } from '../helpers/staticTags'
import { truncate } from '../helpers/truncate'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Dialogs } from '../models/dialog'
import { Post } from '../models/post'
import { Project } from '../models/project'
import { User } from '../models/user'
import { Routes } from '../services/RouteService'
import Attachments from './Attachments'
import KukidoAvatar from './Avatar'
import './mentions.css'
import PostComments from './PostComments'
import PostLikes from './PostLikes'
import PostPin from './PostPin'
import Tag from './Tag'

interface PostCardProps {
	post: Post
	lean?: boolean
	editAllowed?: boolean
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		marginBottom: theme.spacing(2),
		overflow: 'visible'
	},
	storyHeader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	userName: {
		paddingLeft: theme.spacing(1)
	},
	chip: {
		maxWidth: '100%',
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	},
	chipWrapper: {
		marginTop: theme.spacing(1)
	},
	storyHeaderLeft: {
		display: 'flex',
		alignItems: 'center'
	},
	actions: {
		display: 'flex',
		padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`
	},
	content: {
		paddingBottom: 0
	},
	openDialog: {
		cursor: 'pointer',
		textDecoration: 'underline'
	},
	headline: {
		marginTop: theme.spacing(1)
	},
	exportContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		minWidth: theme.spacing(4)
	},
	exportIcon: {
		cursor: 'pointer'
	},
	isPinned: {
		border: `1px solid ${theme.palette.primary.main}`
	}
}))

const truncateLength = 240

const PostCard = (props: PostCardProps) => {
	const classes = useStyles()
	const { post, lean, editAllowed } = props
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch<Dispatch>()
	const { isOwnPost, isPinned, isProjectPage } = useSelector((state: GlobalState) => ({
		isOwnPost: state.user.currentUser?._id === (typeof post.user === 'string' ? post.user : post.user?._id),
		isPinned: (props.post.project as Project)?.pinnedPost && (props.post.project as Project)?.pinnedPost === post._id,
		isProjectPage: state.environment.currentRoute?.path.includes('/projects/')
	}))
	const [viewMore, setViewMore] = useState(false)

	React.useEffect(() => {
		post && activateYoutubeEmbeds()
	}, [post])

	return (
		<Card className={`${classes.root} ${isPinned && isProjectPage ? classes.isPinned : ''}`}>
			<CardContent className={classes.content} id={post._id}>
				<div className={classes.storyHeader}>
					<div className={classes.storyHeaderLeft}>
						{lean ? (
							<>
								<Typography variant='caption'>
									<TimeAgo datetime={post.createdAt} locale={i18n.language} />
								</Typography>
							</>
						) : (
							<>
								<KukidoAvatar user={post.user as User} showName />
								<Typography variant='caption' className={classes.userName}>
									| <TimeAgo datetime={post.createdAt} locale={i18n.language} />
								</Typography>
							</>
						)}
					</div>
					<div style={{ display: 'flex' }}>
						<PostPin post={post} />
						<PostLikes post={post} />
					</div>
				</div>
				{(post.project as Project)?.name && (
					<Typography
						variant='body2'
						display='block'
						style={{ marginTop: 10 }}
						onClick={() => {
							dispatch.environment.navigate({
								path: `${Routes.projects}/${(post.project as Project)._id}`,
								name: (post.project as Project).name,
								routeComponent: () => <div>{(post.project as Project).name}</div>
							})
							dispatch.project.selectProject((post.project as Project)._id)
						}}>
						<span>
							{t('post.inProject')} <span className={classes.openDialog}>{(post.project as Project).name}</span>
						</span>
					</Typography>
				)}
				<Typography variant='h5' className={classes.headline}>
					{post.title}
				</Typography>
				<Typography variant='body1' component='span'>
					<span>{parse(viewMore ? post.body : truncate(post.body, truncateLength))}</span>
				</Typography>
				{post.tags && (
					<div className={classes.chipWrapper}>
						{post.tags
							.sort((a, b) => StaticTags.find((t) => t.name === a)!.id - StaticTags.find((t) => t.name === b)!.id)
							.map((tag) => (
								<Tag name={tag} key={tag} />
							))}
					</div>
				)}
				<Attachments attachments={post.files} />
			</CardContent>
			<CardActions className={classes.actions}>
				{post.body.length >= truncateLength && (
					<Button variant='outlined' onClick={() => setViewMore(!viewMore)}>
						<Typography>{viewMore ? t('basic.less') : t('basic.more')}</Typography>
					</Button>
				)}
				{isOwnPost && editAllowed && (
					<Button variant='outlined' onClick={() => dispatch.dialog.openDialog({ dialog: Dialogs.createPost, editData: post })}>
						<Typography>{t('basic.edit')}</Typography>
					</Button>
				)}
			</CardActions>
			<PostComments post={post} />
		</Card>
	)
}

export default PostCard
