import React from 'react'
import { useDispatch } from 'react-redux'
import { Dispatch } from '../models/bootstrap'
import RouteService, { Routes } from '../services/RouteService'

interface KukidoRedirectProps {
	toLogin?: boolean
}

const KukidoRedirect: React.FC<KukidoRedirectProps> = (props) => {
	const dispatch = useDispatch<Dispatch>()

	React.useEffect(() => {
		dispatch.environment.navigate(props.toLogin ? RouteService.getByPath(Routes.login) : RouteService.getByPath(Routes.home))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <></>
}

export default KukidoRedirect
