import { makeStyles, Typography } from '@material-ui/core'
import { FavoriteBorderOutlined, FavoriteOutlined } from '@material-ui/icons'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Post } from '../models/post'

const useStyles = makeStyles((theme) => ({
	likeIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginLeft: theme.spacing(1),
		cursor: 'pointer'
	},
	likeContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	}
}))

interface Props {
	post: Post
}

const PostLikes: React.FC<Props> = (props) => {
	const classes = useStyles()
	const { post } = props
	const dispatch = useDispatch<Dispatch>()
	const { currentUser } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser
	}))
	const currentUserHasLiked = post.likes?.some((userId) => userId === currentUser?._id)

	return (
		<div className={classes.likeContainer}>
			{post.likes && post.likes.length > 0 && <Typography variant='body2'>{post.likes.length}</Typography>}
			{currentUserHasLiked ? (
				<div className={classes.likeIcon} onClick={() => dispatch.post.unlikePost(post._id)}>
					<FavoriteOutlined color='primary' />
				</div>
			) : (
				<div className={classes.likeIcon} onClick={() => dispatch.post.likePost(post._id)}>
					<FavoriteBorderOutlined color='primary' />
				</div>
			)}
		</div>
	)
}

export default PostLikes
