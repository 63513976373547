import axios from 'axios';
import { KukidoNotification } from '../models/notification';

class NotificationService {
    async mine(): Promise<Array<KukidoNotification>> {
        const response = await axios.get<Array<KukidoNotification>>('/notification/mine');
        return response.data;
    }

    async seen(notificationId: string): Promise<void> {
        await axios.get(`/notification/seen/${notificationId}`)
    }
}

export default NotificationService