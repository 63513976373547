import axios from 'axios';
import { Post } from '../models/post';
import { Project, ProjectData } from '../models/project';
import { User } from '../models/user';

class ProjectService {
    async create(projectData: ProjectData): Promise<string> {
        const response = await axios.post<Project>('/project/create', { ...projectData });
        return response.data._id;
    }

    async getAll(): Promise<Array<Project>> {
        const response = await axios.get<{ projects: Array<Project> }>('/project/');
        return response.data.projects;
    }

    async getById(_id: string): Promise<Project> {
        const response = await axios.get<{ project: Project }>(`/project/${_id}`);
        return response.data.project;
    }

    async update(projectData: Project): Promise<Project> {
        const response = await axios.put<{ updatedProject: Project }>(`/project/${projectData._id}`, projectData);
        return response.data.updatedProject;
    }

    async getUsers(_id: string): Promise<Array<User>> {
        const response = await axios.get<Array<User>>(`/project/${_id}/users`);
        return response.data;
    }

    async delete(id: string): Promise<boolean> {
        const response = await axios.delete<{ projectDeleted: boolean }>(`/project/${id}`);
        return response.data.projectDeleted;
    }

    async exportFullFeed(id: string): Promise<string> {
        const response = await axios.get(`/project/exportFullFeed/${id}`)
        return response.data
    }

    async pinPost(postId: string, projectId: string): Promise<void> {
        await axios.post<Project>(`/project/${projectId}/pinPost`, { postId });
    }

    async unpinPost(projectId: string): Promise<void> {
        await axios.post<Project>(`/project/${projectId}/unpinPost`);
    }

    async getPinnedPost(projectId: string): Promise<Post | null> {
        const response = await axios.get<Post>(`/project/${projectId}/pinnedPost`);
        return response.data
    }
}

export default ProjectService;