import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { documentTitle } from '../App';
import NotificationService from '../services/NotificationService';
import { Dispatch, GlobalState } from './bootstrap';

export interface KukidoNotification extends NotificationData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface NotificationData {
    type: 'postLikes' | 'postComments' | 'involvedPostComments' | 'mention' | 'unlockedForProject' | 'chatMessageReceived',
    user: string, // The user the notification is for
    data: PostRelatedNotification | ProjectRelatedNotification | ChatRelatedNotification
}

export interface PostRelatedNotification {
    post: string; // postId
}

export interface ProjectRelatedNotification {
    project: string; // projectId
}

export interface ChatRelatedNotification {
    fromUserId: string; // userId
}

export interface PostLikesNotificationData extends PostRelatedNotification {
    likes: string[], // userIds of people who liked the post
}

export interface PostCommentsNotificationData extends PostRelatedNotification {
    comments: string[], // userIds of people who commented the post
}

export interface InvolvedPostCommentsNotificationData extends PostRelatedNotification {
    comments: string[], // userIds of people who also commented the post
}

export interface MentionNotificationData extends PostRelatedNotification {
    byUserId: string,
}

export interface UnlockedForProjectNotificationData extends ProjectRelatedNotification {
    byUserId: string,
}

export interface ChatMessageReceivedNotificationData extends ChatRelatedNotification {
    
}

type NotificationState = {
    notifications: KukidoNotification[];
}

const notificationService = new NotificationService();

const initialState = {
    notifications: [],
} as NotificationState

export const notification = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setNotifications: (state: NotificationState, notifications: KukidoNotification[]) => ({
            ...state,
            notifications
        }),
    },
    effects: (dispatch: Dispatch) => ({
        getMine: async (payload: void, state: GlobalState): Promise<void> => {
            const ns = await notificationService.mine()
            dispatch.notification.setNotifications(ns)
            document.title = documentTitle + (ns.length > 0 ? ` (${ns.length})` : '')
        },
        seen: async (nId: string, state: GlobalState): Promise<void> => {
            await notificationService.seen(nId)
            const newNotifcations = state.notification.notifications.filter(n => n._id !== nId)
            dispatch.notification.setNotifications(newNotifcations)
            document.title = documentTitle + (newNotifcations.length > 0 ? ` (${newNotifcations.length})` : '')
        },
    })
});