import axios from 'axios';
import { RegisterUserData, User, UserData } from '../models/user';

class UserService {
    async create(userData: UserData): Promise<string> {
        const response = await axios.post<User>('/user/create', { ...userData })
        return response.data._id
    }

    async getAll(): Promise<Array<User>> {
        const response = await axios.get<{ users: Array<User> }>('/user/getAll')
        return response.data.users
    }

    async getById(_id: string): Promise<User> {
        const response = await axios.get<{ user: User }>(`/user/${_id}`)
        return response.data.user
    }

    async search(term: string): Promise<User[]> {
        if (!term || term.length < 1) return []
        const response = await axios.get<User[]>(`/user/search/${term}`)
        return response.data
    }

    async login(email: string, password: string): Promise<LoginSuccessResponse> {
        const response = await axios.post<LoginSuccessResponse>('/user/login', { email, password })

        localStorage.setItem('refreshToken', response.data.refreshToken)
        axios.defaults.headers.common['Authorization'] = response.data.accessToken

        return response.data
    }

    async delete(id: string): Promise<boolean> {
        const response = await axios.delete<{ userDeleted: boolean }>(`/user/${id}`)
        return response.data.userDeleted
    }

    async getNewAccessToken(): Promise<TokenSuccessResponse|ErrorResponse> {
        const refreshToken = localStorage.getItem('refreshToken');
        const response = await axios.post<TokenSuccessResponse>('/user/accessToken', { refreshToken })

        localStorage.setItem('refreshToken', response.data.refreshToken)
        axios.defaults.headers.common['Authorization'] = response.data.accessToken

        return {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
            user: response.data.user
        }
    }

    async update(userData: User): Promise<User> {
        const response = await axios.put<{ updatedUser: User }>(`/user/${userData._id}`, { ...userData })
        return response.data.updatedUser
    }

    async uploadAvatar(avatar: Blob, name: string): Promise<string> {
        let formData = new FormData()
        let file = new File([avatar], name)
        formData.append('avatar', file)
        const response = await axios.post<any>('/user/uploadAvatar', formData, {
            headers: { 'Content-Type': 'multipart/form-data'}
        })
        return response.data.avatarUrl
    }

    async invite(email: string, asStarkmacher: boolean): Promise<{ inviteSent: boolean }|ErrorResponse> {
        const response = await axios.post<{ inviteSent: boolean }>('/user/invite', { receiver: email, asStarkmacher })
        return { inviteSent: response.data.inviteSent}
    }

    async register(payload: RegisterUserData): Promise<LoginSuccessResponse|ErrorResponse> {
        const response = await axios.post<LoginSuccessResponse>('/user/register', payload)

        localStorage.setItem('refreshToken', response.data.refreshToken)
        axios.defaults.headers.common['Authorization'] = response.data.accessToken

        return response.data;
    }

    async changePassword(payload: ChangePasswordPayload): Promise<User> {
        const response = await axios.post('/user/changePassword', { oldPassword: payload.oldPassword, newPassword: payload.newPassword })
        return response.data
    }

    async sendRecovery(email: string): Promise<{ recoverySent: boolean }|ErrorResponse> {
        const response = await axios.post('/user/sendRecovery', { email })
        return response.data
    }

    async recoverAccount(payload: RecoverAccountPayload): Promise<{ recoverySuccess: boolean }|ErrorResponse> {
        const response = await axios.post('/user/recover', payload)
        return response.data
    }

    async unlockUserForProject(payload: { projectId: string; userId: string }): Promise<User> {
        const response = await axios.post<{ updatedUser: User }>('/user/unlockUserForProject', payload)
        return response.data.updatedUser
    }

    async lockUserForProject(payload: { projectId: string; userId: string }): Promise<User> {
        const response = await axios.post<{ updatedUser: User }>('/user/lockUserForProject', payload)
        return response.data.updatedUser
    }
}

export interface RecoverAccountPayload {
    email: string;
    newPassword: string;
    recoveryHash: string;
}

export interface ChangePasswordPayload {
    oldPassword: string;
    newPassword: string;
}

export interface LoginSuccessResponse {
    accessToken: string,
    refreshToken: string,
    user: User,
}

export interface TokenSuccessResponse {
    accessToken: string,
    refreshToken: string,
    user: User,
}

export interface ErrorResponse {
    message: string;
}

export default UserService;