import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip, useMediaQuery, Typography } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { drawerWidth } from '../App'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Dialogs } from '../models/dialog'
import { Role } from '../models/user'
import RouteService, { FullRoute, Routes, RouteTypes } from '../services/RouteService'
import { ReactComponent as Logo } from '../assets/images/LogoKUKIDOEntwurf.svg'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		position: 'fixed',
		zIndex: 2
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	title: {
		flexGrow: 1
	},
	drawerItem: {
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`
	},
	list: {
		width: drawerWidth
	},
	toolbar: {
		...theme.mixins.toolbar,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		cursor: 'pointer'
	},
	logo: {
		width: 37,
		height: 37,
		marginLeft: theme.spacing(1)
	}
}))

const KukidoDrawer = () => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { t } = useTranslation()
	const [drawerOpen, setDrawerOpen] = useState(false)
	const { currentUser, projects } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser,
		projects: state.project.projects
	}))
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const handleDrawerItemClick = (route: FullRoute) => {
		dispatch.environment.navigate(route)
		isMobile && toggleDrawer()
	}
	const toggleDrawer = () => setDrawerOpen(!drawerOpen)

	return (
		<>
			{isMobile && (
				<IconButton edge='start' className={classes.menuButton} onClick={toggleDrawer}>
					<MenuIcon style={{ fill: theme.palette.getContrastText(theme.palette.primary.main) }} />
				</IconButton>
			)}
			<Drawer
				anchor='left'
				variant={isMobile ? 'temporary' : 'permanent'}
				open={isMobile ? drawerOpen : true}
				onClose={isMobile ? toggleDrawer : undefined}>
				{!isMobile && (
					<>
						<div className={classes.toolbar} onClick={() => dispatch.environment.navigate(RouteService.getByPath(Routes.home))}>
							<Typography variant='h5' style={{ fontFamily: 'Montserrat' }}>
								KUKIDO
							</Typography>
							<Logo className={classes.logo} />
						</div>
						<Divider />
					</>
				)}
				<List className={classes.list}>
					{RouteService.getAllowed(currentUser?.role, RouteTypes.drawer).map((route) => (
						<ListItem button key={route.name} className={classes.drawerItem} onClick={() => handleDrawerItemClick(route)}>
							{route.iconComponent && (
								<ListItemIcon>
									<route.iconComponent />
								</ListItemIcon>
							)}
							<ListItemText primary={t(`pages.${route.name}`)} />
						</ListItem>
					))}
				</List>
				{projects && (
					<>
						<Divider />
						<List className={classes.list}>
							{projects.map((p) => {
								const isDisabled =
									currentUser?.role !== Role.starkmacher &&
									currentUser?.role !== Role.admin &&
									!currentUser?.projects.some((userProjectId) => userProjectId === p._id)
								const Wrapper: React.FC<any> = ({ children }) =>
									isDisabled ? (
										<Tooltip title={t('project.noAccessHelper') ?? ''}>
											<span>{children}</span>
										</Tooltip>
									) : (
										children
									)
								return (
									<Wrapper key={p.name}>
										<ListItem
											style={isDisabled ? { pointerEvents: 'none' } : {}} // Safari Helper
											button
											key={p.name}
											className={classes.drawerItem}
											disabled={isDisabled}
											onClick={() => {
												handleDrawerItemClick({
													path: `${Routes.projects}/${p._id}`,
													name: p.name,
													routeComponent: () => <div>{p.name}</div>
												})
												dispatch.project.selectProject(p._id)
											}}>
											<ListItemText primary={p.name} />
										</ListItem>
									</Wrapper>
								)
							})}
							{currentUser?.role !== Role.user && (
								<ListItem
									button
									key={'createNewProject'}
									className={classes.drawerItem}
									onClick={() => dispatch.dialog.openDialog({ dialog: Dialogs.createProject })}>
									<ListItemIcon>
										<AddIcon />
									</ListItemIcon>
									<ListItemText primary={t('project.new')} />
								</ListItem>
							)}
						</List>
					</>
				)}
			</Drawer>
		</>
	)
}

export default KukidoDrawer
