import { Avatar, makeStyles, Typography } from '@material-ui/core'
import { lighten } from '@material-ui/core/styles'
import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { UserData } from '../models/user'

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: theme.spacing(4),
		height: theme.spacing(4)
	},
	pulse: {
		animation: `$pulse 2s infinite`
	},
	'@keyframes pulse': {
		'0%': {
			transform: 'scale(0.97)',
			boxShadow: `0 0 0 0 ${lighten(theme.palette.secondary.main, 0.1)}`
		},
		'70%': {
			transform: 'scale(1)',
			boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)'
		},
		'100%': {
			transform: 'scale(0.97)',
			boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)'
		}
	},
	large: {
		width: theme.spacing(10),
		height: theme.spacing(10)
	},
	userName: {
		paddingLeft: theme.spacing(1)
	},
	wrapper: {
		display: 'flex',
		alignItems: 'center',
		cursor: 'pointer'
	},
	noClick: {
		cursor: 'default'
	}
}))

interface KukidoAvatarProps {
	user: UserData | null
	pulse?: boolean
	onClick?: MouseEventHandler<HTMLDivElement>
	large?: boolean
	showName?: boolean
}

const KukidoAvatar: React.FC<KukidoAvatarProps> = (props) => {
	const classes = useStyles()
	const { t } = useTranslation()

	// Handle deleted users.
	const user = props.user ?? { _id: undefined, avatar: undefined }
	const displayName = props.user ? `${props.user.firstName} ${props.user.lastName}` : t('user.deletedUser')

	return (
		<span
			onClick={props.user && props.onClick ? props.onClick : undefined}
			className={`${classes.wrapper} ${!(props.user && props.onClick) ? classes.noClick : ''}`}>
			<Avatar
				alt={displayName}
				className={`${classes.avatar} ${props.pulse ? classes.pulse : ''} ${props.large ? classes.large : ''}`}
				src={user.avatar ?? undefined}
			/>
			{props.showName && (
				<Typography variant='caption' className={classes.userName}>
					{displayName}
				</Typography>
			)}
		</span>
	)
}

export default KukidoAvatar
