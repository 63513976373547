import { init, RematchDispatch, RematchRootState } from "@rematch/core";
import { createMemoryHistory } from "history";
import { models, RootModel } from ".";

export const store = init({
    name: 'Kukido store',
    models
})

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
export type GlobalState = RematchRootState<RootModel>;
export const memoryHistory = createMemoryHistory();