import { createGenerateClassName, StylesProvider } from '@material-ui/core'
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import './i18n'
import './index.css'
import { store } from './models/bootstrap'

console.info('API_URL', process.env.REACT_APP_API_URL)

axios.defaults.baseURL = process.env.REACT_APP_API_URL
// axios.interceptors.response.use((response) => response, (error) => {
//   store.dispatch.environment.enqueueSnack({ message: error.response?.data?.message ?? 'Something went wrong.', options: { variant: 'error' }})
//   return Promise.reject(error.message);
// });

export const generateClassName = createGenerateClassName({
	seed: 'kukido--',
	productionPrefix: 'kukido--'
})

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<StylesProvider generateClassName={generateClassName}>
				<App />
			</StylesProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)
