import { createModel } from '@rematch/core';
import { History } from 'history';
import { SharedProps } from 'notistack';
import * as timeago from 'timeago.js';
import de from 'timeago.js/lib/lang/de';
import { RootModel } from '.';
import FileService from '../services/FileService';
import RouteService, { FullRoute } from '../services/RouteService';
import { Dispatch, GlobalState } from './bootstrap';

type EnvironmentState = {
    history: History | null;
    currentRoute: FullRoute | null;
    enqueueSnack: Function;
    closeSnack: Function;
}

const fileService = new FileService();

export const environment = createModel<RootModel>()({
    state: {
        history: null,
        currentRoute: null,
        enqueueSnack: () => console.error('Snackbar not initialized yet.'),
        closeSnack: () => console.error('Snackbar not initialized yet.')
    } as EnvironmentState,
    reducers: {
        setHistory: (state: EnvironmentState, history: History | null) => ({
            ...state,
            history
        }),
        setCurrentRoute: (state: EnvironmentState, currentRoute: FullRoute | null) => ({
            ...state,
            currentRoute
        }),
        setEnqueueSnack: (state: EnvironmentState, enqueueSnack: Function) => ({
            ...state,
            enqueueSnack
        }),
        setCloseSnack: (state: EnvironmentState, closeSnack: Function) => ({
            ...state,
            closeSnack
        }),
    },
    effects: (dispatch: Dispatch) => ({
        navigate: async (route: FullRoute, state: GlobalState): Promise<void> => {
            dispatch.project.setCurrentProject(null)
            state.environment.history && state.environment.history.push(route.path)
            dispatch.environment.setHistory(state.environment.history)
            dispatch.environment.setCurrentRoute(route)
            window.scroll(0, 0)
        },
        init: async (history: History, state: GlobalState): Promise<void> => {
            const needsTenantSelection = await dispatch.user.getNewAccessToken()
            dispatch.environment.setHistory(history)
            !needsTenantSelection && dispatch.environment.setCurrentRoute(RouteService.getAll().find(r => r.path === history.location.pathname) ?? null)
            timeago.register('de', de);
        },
        enqueueSnack: (payload: { message: string, options?: SharedProps }, state: GlobalState): void => {
            const key = state.environment.enqueueSnack(payload.message, { variant: 'success', ...payload.options, onClick: () => state.environment.closeSnack(key) })
        },
        uploadFile: async (file: File, state: GlobalState): Promise<string> => {
            const url = await fileService.upload(file)
            return url
        }
    })
});