import { AccountCircle, Home, LockOpen, NotificationsActive, People, Chat } from "@material-ui/icons";
import React from "react";
import { Role } from "../models/user";

const ProfilePage = React.lazy(() => import("../pages/ProfilePage"))
const HomePage = React.lazy(() => import("../pages/HomePage"))
const LoginPage = React.lazy(() => import("../pages/LoginPage"))
const RegisterPage = React.lazy(() => import("../pages/RegisterPage"))
const RecoverAccountPage = React.lazy(() => import("../pages/RecoverAccountPage"))
const UserMgmtPage = React.lazy(() => import("../pages/UserMgmtPage"))
const NotificationsPage = React.lazy(() => import("../pages/NotificationsPage"))
const ChatPage = React.lazy(() => import("../pages/ChatPage"))

export enum Routes {
    login = '/login',
    home = '/',
    projects = '/projects',
    admin = '/admin',
    profile = '/profile',
    register = '/register',
    recovery = '/recovery',
    selectTenant = '/selectTenant',
    userManagement = '/userManagement',
    notifications = '/notifications',
    chat = '/chat',
}

export interface FullRoute {
    path: Routes | string;
    name: string;
    permissions?: Array<Role>;
    routeType?: RouteTypes;
    iconComponent?: React.FC;
    routeComponent: React.FC;
    anonymous?: boolean;
}

export enum RouteTypes {
    drawer = 'drawer',
    userMenu = 'userMenu',
    notificationMenu = 'notificationMenu',
    hidden = 'hidden'
}

class RouteService {
    fullRoutes: Array<FullRoute> = [
        {
            path: Routes.login,
            name: 'Login',
            permissions: [Role.user, Role.admin, Role.starkmacher],
            routeType: RouteTypes.hidden,
            iconComponent: LockOpen,
            routeComponent: LoginPage,
            anonymous: true,
        },
        {
            path: Routes.home,
            name: 'Home',
            permissions: [Role.user, Role.admin, Role.starkmacher],
            routeType: RouteTypes.drawer,
            iconComponent: Home,
            routeComponent: HomePage
        },
        {
            path: Routes.notifications,
            name: 'Notifications',
            permissions: [Role.admin, Role.starkmacher, Role.user],
            routeType: RouteTypes.notificationMenu,
            iconComponent: NotificationsActive,
            routeComponent: NotificationsPage
        },
        {
            path: Routes.profile,
            name: 'Profile',
            permissions: [Role.user, Role.admin, Role.starkmacher],
            routeType: RouteTypes.userMenu,
            iconComponent: AccountCircle,
            routeComponent: ProfilePage
        },
        {
            path: Routes.register,
            name: 'Register',
            permissions: [],
            routeType: RouteTypes.hidden,
            iconComponent: LockOpen,
            routeComponent: RegisterPage,
            anonymous: true,
        },
        {
            path: Routes.recovery,
            name: 'Recover',
            permissions: [],
            routeType: RouteTypes.hidden,
            iconComponent: LockOpen,
            routeComponent: RecoverAccountPage,
            anonymous: true,
        },
        {
            path: Routes.chat,
            name: 'Chat',
            permissions: [Role.admin, Role.starkmacher, Role.user],
            routeType: RouteTypes.drawer,
            iconComponent: Chat,
            routeComponent: ChatPage
        },
        {
            path: Routes.userManagement,
            name: 'UserManagement',
            permissions: [Role.admin, Role.starkmacher],
            routeType: RouteTypes.drawer,
            iconComponent: People,
            routeComponent: UserMgmtPage
        }
    ];

    getByPath(path: Routes): FullRoute {
        const matchedRoute = this.fullRoutes.find(route => route.path === path);
        return matchedRoute ?? this.fullRoutes.find(route => route.path === Routes.home)!
    }

    getByName(name: string): FullRoute {
        const matchedRoute = this.fullRoutes.find(route => route.name === name);
        return matchedRoute ?? this.fullRoutes.find(route => route.path === Routes.home)!
    }

    getAll(routeType?: RouteTypes) {
        return routeType ? this.fullRoutes.filter(r => r.routeType === routeType) : this.fullRoutes;
    }

    getAllowed(role?: Role, routeType?: RouteTypes): Array<FullRoute> {
        let routes: Array<FullRoute> = [];

        if (!role) routes = [this.fullRoutes.find(route => route.path === Routes.login)!]
        if (role) routes = this.fullRoutes.filter(route => route.permissions?.some(p => p === role))
        if (routeType) routes = routes.filter(r => r.routeType === routeType)

        return routes;
    }

    getAnonymousRoutes() {
        return this.fullRoutes.filter(fullRoute => fullRoute.anonymous)
    }

    isOnAnonymousPage(): boolean {
        return this.fullRoutes.some(fullRoute => window.location.pathname.startsWith(fullRoute.path))
    }
}

export default new RouteService();