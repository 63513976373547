import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { Dispatch, GlobalState } from './bootstrap';

export enum Dialogs {
    createProject = 'createProject',
    createPost = 'createPost',
    startChat = 'startChat',
}

type DialogState = {
    currentDialog: Dialogs | null;
    open: boolean;
    entityId: string | null;
    editData: any;
}

export const dialog = createModel<RootModel>()({
    state: {
        currentDialog: null,
        open: false,
        entityId: null,
        editData: null,
    } as DialogState,
    reducers: {
        setCurrentDialog: (state: DialogState, currentDialog: Dialogs | null) => ({
            ...state,
            currentDialog
        }),
        setOpen: (state: DialogState, open: boolean) => ({
            ...state,
            open
        }),
        setEntityId: (state: DialogState, entityId: string | null) => ({
            ...state,
            entityId
        }),
        setEditData: (state: DialogState, editData: object | null) => ({
            ...state,
            editData
        }),
    },
    effects: (dispatch: Dispatch) => ({
        openDialog: async (payload: { dialog: Dialogs, entityId?: string, editData?: any }, state: GlobalState): Promise<void> => {
            dispatch.dialog.setCurrentDialog(payload.dialog)
            dispatch.dialog.setOpen(true)
            payload.entityId && dispatch.dialog.setEntityId(payload.entityId)
            payload.editData && dispatch.dialog.setEditData(payload.editData)
        },
        closeDialog: async (payload: void, state: GlobalState): Promise<void> => {
            dispatch.dialog.setOpen(false)
            dispatch.dialog.setCurrentDialog(null)
            dispatch.dialog.setEntityId(null)
            dispatch.dialog.setEditData(null)
        }
    })
});