import { Models } from "@rematch/core"
import { dialog } from './dialog'
import { environment } from './environment'
import { user } from './user'
import { project } from './project'
import { post } from './post'
import { notification } from './notification'
import { chat } from './chat'

export interface RootModel extends Models<RootModel> {
    user: typeof user;
    environment: typeof environment;
    dialog: typeof dialog;
    project: typeof project;
    post: typeof post;
    notification: typeof notification;
    chat: typeof chat;
}

export const models: RootModel = {
    user,
    environment,
    dialog,
    project,
    post,
    notification,
    chat
}