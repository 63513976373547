import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { downloadFile } from '../helpers/downloadFile'
import { humanReadableFileSize } from '../helpers/humanReadableFileSizes'
import { KukidoAttachment } from '../models/project'

interface Props {
	attachments: KukidoAttachment[] | undefined | null
}

const Attachments: React.FC<Props> = ({ attachments }) => {
	if (!attachments || attachments.length < 1) return null
	return (
		<List style={{ maxHeight: 240, overflow: 'auto' }}>
			{attachments.map((f, index) => (
				<ListItem key={`${f.name}-${f.size}-${index}`}>
					<ListItemText>
						{f.name} | {humanReadableFileSize(f.size)}
					</ListItemText>
					<ListItemSecondaryAction>
						<IconButton edge='end' aria-label='downloadFile' onClick={() => downloadFile(f)}>
							<CloudDownload />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			))}
		</List>
	)
}

export default Attachments
