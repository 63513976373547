import axios from 'axios';
import { AvailableChat, ChatMessage } from '../models/chat';
import { KukidoAttachment } from '../models/project';

class ChatService {
    async availableChats(): Promise<Array<AvailableChat>> {
        const response = await axios.get<Array<AvailableChat>>('/chat/availableChats');
        return response.data;
    }

    async sendMessage(to: string, content: string, files?: KukidoAttachment[]): Promise<void> {
        await axios.post('/chat/sendChatMessage', { to, content, files })
    }

    async getFeedFrom(payload: { from: string, newerThan?: string, olderThan?: string }): Promise<Array<ChatMessage>> {
        let route = `/chat/feedFrom/${payload.from}`
        if (payload.newerThan) route += `?newerThan=${payload.newerThan}`
        if (payload.olderThan) route += `?olderThan=${payload.olderThan}`
        const response = await axios.get<{ chatMessages: Array<ChatMessage> }>(route);
        return response.data.chatMessages;
    }

    async deleteMessage(id: string): Promise<void> {
        await axios.delete(`/chat/deleteChatMessage/${id}`)
    }
}

export default ChatService