import {
	Avatar,
	Button,
	Card,
	CircularProgress,
	Fab,
	Grow,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	makeStyles,
	TextField,
	Typography,
	useScrollTrigger
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import Attachments from '../components/Attachments'
import InfiniteScroll from '../components/InfiniteScroll'
import PostCard from '../components/PostCard'
import { activateYoutubeEmbeds } from '../helpers/embed'
import { Dispatch, GlobalState } from '../models/bootstrap'
import { Dialogs } from '../models/dialog'
import { Post } from '../models/post'
import { Project } from '../models/project'
import { Role, User } from '../models/user'

interface Props {
	project: Project
}

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(1),
		maxWidth: theme.breakpoints.width('lg'),
		margin: '0 auto'
	},
	postsContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: theme.breakpoints.width('md'),
		margin: `${theme.spacing(2)}px auto 0`
	},
	button: {
		marginTop: theme.spacing(1)
	},
	scrollTopFab: {
		position: 'fixed',
		bottom: theme.spacing(10),
		right: theme.spacing(2)
	},
	fab: {
		position: 'fixed',
		bottom: theme.spacing(2),
		right: theme.spacing(2)
	},
	section: {
		marginBottom: theme.spacing(2)
	},
	userList: {
		overflow: 'auto',
		maxHeight: 270
	},
	description: {
		'& p': {
			marginTop: 0
		}
	},
	descriptionCard: {
		padding: 24,
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'space-between'
	},
	userListContainer: {
		flexGrow: 1,
		flexBasis: '20%',
		borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
		paddingLeft: theme.spacing(2)
	},
	descriptionContent: {
		marginRight: 20,
		flexGrow: 3
	},
	actionButton: {
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(1)
	}
}))

const ProjectPage: React.FC<Props> = (props) => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { t } = useTranslation()
	const scrollTrigger = useScrollTrigger({ disableHysteresis: true })
	const { posts, hasMore, currentUser, projectUsers, pinnedPost } = useSelector((state: GlobalState) => {
		const pinnedPost = props.project.pinnedPost ? state.post.postsById[props.project.pinnedPost] : null
		const posts = state.post.postsByProjectId[props.project._id] ?? []
		return {
			posts: pinnedPost ? posts.filter((p) => p._id !== pinnedPost._id) : posts,
			hasMore: state.post.projectByIdHasMore[props.project._id] ?? false,
			currentUser: state.user.currentUser,
			projectUsers: state.user.usersByProjectId[props.project._id] ?? [],
			pinnedPost
		}
	})
	const [userFilter, setUserFilter] = useState<string>('')
	const [filteredUsers, setFilteredUsers] = useState<Array<User>>([])

	useEffect(() => {
		setFilteredUsers(
			projectUsers.filter((u) => u.lastName.includes(userFilter) || u.firstName.includes(userFilter) || u.email.includes(userFilter))
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userFilter, projectUsers])

	const EmptyState = () => (
		<>
			<Typography variant='body2'>{t('post.noPostsYet')}</Typography>
			<Button
				className={classes.button}
				variant='contained'
				color='primary'
				onClick={() => dispatch.dialog.openDialog({ dialog: Dialogs.createPost })}>
				{t('post.writeFirstPost')}
			</Button>
		</>
	)

	useEffect(() => {
		props.project && activateYoutubeEmbeds()
	}, [props.project])

	return (
		<div className={classes.root}>
			<Card className={classes.descriptionCard}>
				<div className={classes.descriptionContent}>
					<div className={`${classes.section} ${classes.description}`}>{parse(props.project.description)}</div>
					<div className={classes.section}>
						<Attachments attachments={props.project.files} />
					</div>
					{(currentUser?.role === Role.starkmacher || currentUser?.role === Role.admin) && (
						<div className={classes.section}>
							<Button
								className={classes.actionButton}
								variant='outlined'
								onClick={() => dispatch.dialog.openDialog({ dialog: Dialogs.createProject, editData: props.project })}>
								<Typography>{t('project.edit')}</Typography>
							</Button>
							<Button
								className={classes.actionButton}
								variant='outlined'
								onClick={() => dispatch.project.exportFullFeed({ projectId: props.project._id })}>
								<Typography>{t('project.exportFullFeed')}</Typography>
							</Button>
						</div>
					)}
				</div>
				<div className={`${classes.section} ${classes.userListContainer}`}>
					<Typography>{t('project.involvedUsers')}</Typography>
					<TextField
						margin='dense'
						fullWidth
						variant='filled'
						label={t('basic.search')}
						onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setUserFilter(event.target.value)}
						autoComplete='off'
					/>
					{(!projectUsers || projectUsers.length < 1) && <CircularProgress />}
					<List className={classes.userList}>
						{filteredUsers.map((u) => {
							return (
								<ListItem key={u._id} button>
									<ListItemAvatar>
										<Avatar alt={u.firstName} src={u.avatar ?? undefined} />
									</ListItemAvatar>
									<ListItemText primary={`${u.firstName} ${u.lastName}`} />
								</ListItem>
							)
						})}
					</List>
				</div>
			</Card>
			<div className={classes.postsContainer}>
				{pinnedPost && <PostCard post={pinnedPost} key={`${pinnedPost!._id}-pinned`} />}
				<InfiniteScroll
					fetcher={() => dispatch.post.getPostsByProjectId({ projectId: props.project._id })}
					renderer={(p: Post) => <PostCard post={p} editAllowed key={p._id} />}
					items={posts}
					hasMore={hasMore}
					emptyState={EmptyState}
				/>
			</div>
			<Grow in={scrollTrigger}>
				<Fab
					variant='round'
					color='secondary'
					size='medium'
					className={classes.scrollTopFab}
					onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
					<ArrowUpwardIcon />
				</Fab>
			</Grow>
			<Grow in={true} timeout={700}>
				<Fab
					variant='round'
					color='primary'
					size='medium'
					className={classes.fab}
					onClick={() => dispatch.dialog.openDialog({ dialog: Dialogs.createPost })}>
					<AddIcon />
				</Fab>
			</Grow>
		</div>
	)
}

export default ProjectPage
