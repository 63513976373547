import { Chip, makeStyles } from '@material-ui/core'
import { StaticTags } from '../helpers/staticTags'

interface TagProps {
	name: string
}

const useStyles = makeStyles((theme) => ({
	chip: {
		maxWidth: '100%',
		marginBottom: theme.spacing(1),
		marginRight: theme.spacing(1)
	}
}))

const Tag: React.FC<TagProps> = (props) => {
	const classes = useStyles()

	const getChipStyles = () => {
		const color = StaticTags.find((t) => t.name === props.name)?.color

		return {
			backgroundColor: color,
			color: 'white'
		}
	}

	return <Chip label={props.name} className={classes.chip} size='small' style={getChipStyles()} variant={'outlined'} />
}

export default Tag
