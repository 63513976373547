import { createModel } from '@rematch/core';
import { RootModel } from '.';
import i18n from '../i18n';
import ProjectService from '../services/ProjectService';
import RouteService, { Routes } from '../services/RouteService';
import { Dispatch, GlobalState } from './bootstrap';
import { User } from './user';

export interface Project extends ProjectData {
    _id: string;
    createdAt: string;
    updatedAt: string;
}

export interface KukidoAttachment {
    name: string,
    size: number,
    url: string
}

export interface ProjectData {
    name: string;
    description: string;
    user: string | User;
    files?: Array<KukidoAttachment>;
    pinnedPost?: string;
}

type ProjectState = {
    currentProject: Project | null;
    projects: Project[];
}

const projectService = new ProjectService();

const initialState = {
    currentProject: null,
    projects: [],
} as ProjectState

export const project = createModel<RootModel>()({
    state: initialState,
    reducers: {
        reset: () => ({ ...initialState }),
        setCurrentProject: (state: ProjectState, currentProject: Project | null) => ({
            ...state,
            currentProject
        }),
        setProjects: (state: ProjectState, projects: Project[]) => ({
            ...state,
            projects
        }),
    },
    effects: (dispatch: Dispatch) => ({
        init: async (payload: void, state: GlobalState): Promise<void> => {
            await dispatch.project.getAllProjects()
        },
        createProject: async (projectData: Omit<ProjectData, 'user'>, state: GlobalState): Promise<void> => {
            try {
                if (!state.user.currentUser) throw new Error('No user.')
                await projectService.create({ ...projectData, user: state.user.currentUser._id })
                dispatch.environment.enqueueSnack({message: i18n.t('project.created'), options: { variant: 'success' }})
            } catch(e) {
                dispatch.environment.enqueueSnack({message: i18n.t('project.creationFailed'), options: { variant: 'error' }})
            } finally {
                dispatch.project.getAllProjects()
            }
        },
        selectProject: async (projectId: string, state: GlobalState): Promise<void> => {
            const project = state.project.projects.find(p => p._id === projectId);
            if (!project) return
            dispatch.project.setCurrentProject(project)
            dispatch.post.getPostsByProjectId({ projectId: project._id, fromStart: true })
            dispatch.user.getUsersByProjectId(project._id)
            dispatch.project.getPinnedPost({ projectId: project._id })
        },
        getAllProjects: async (payload: void, state: GlobalState): Promise<void> => {
            const projects = await projectService.getAll()
            dispatch.project.setProjects(projects)
        },
        getProject: async (id: string, state: GlobalState): Promise<void> => {
            const projectAlreadyInState = state.project.projects.find(p => p._id === id)
            const project = await projectService.getById(id)
            const newProjects = projectAlreadyInState ? state.project.projects.map(p => p._id === id ? project : p) : [...state.project.projects, project]
            dispatch.project.setProjects(newProjects)
        },
        updateProject: async (updateProject: Project, state: GlobalState): Promise<void> => {
            const updatedProject = await projectService.update(updateProject)
            dispatch.project.setProjects(state.project.projects.map(p => p._id === updatedProject._id ? updatedProject : p))
            if (state.project.currentProject?._id === updatedProject._id) dispatch.project.setCurrentProject(updatedProject)
        },
        deleteProject: async (payload: { projectId: string }, state: GlobalState): Promise<void> => {
            try {
                await projectService.delete(payload.projectId)
                await dispatch.post.getMainFeed({ fromStart: true })
                await dispatch.project.getAllProjects()
                dispatch.environment.navigate(RouteService.getByPath(Routes.home))
                dispatch.project.setCurrentProject(null)

                dispatch.environment.enqueueSnack({message: i18n.t('project.deleted'), options: { variant: 'success' }})
            } catch (e) {
                dispatch.environment.enqueueSnack({message: i18n.t('project.deletionFailed'), options: { variant: 'error' }})
            } finally {
                dispatch.dialog.closeDialog()
            }
        },
        exportFullFeed: async (payload: { projectId: string }, state: GlobalState): Promise<void> => {
            const html = await projectService.exportFullFeed(payload.projectId)
            const now = new Date();
            let tempEl = document.createElement('a');
            tempEl.href = window.URL.createObjectURL(new Blob([html], { type: 'attachment/text' }))
            tempEl.target = '_blank';
            tempEl.download = `${state.project.currentProject?.name}_${now.toLocaleDateString()}_${now.toLocaleTimeString()}_full_feed_export.html`;
            tempEl.style.display = 'none';
            document.body.appendChild(tempEl);
            tempEl.click();
            document.body.removeChild(tempEl);
        },
        getPinnedPost: async (payload: { projectId: string }, state: GlobalState): Promise<void> => {
            const post = await projectService.getPinnedPost(payload.projectId)
            post && dispatch.post.setPostById(post)
        },
        pinPost: async (payload: { projectId: string, postId: string }, state: GlobalState): Promise<void> => {
            await projectService.pinPost(payload.postId, payload.projectId)
            dispatch.project.setProjects(state.project.projects.map(p => p._id === payload.projectId ? { ...p, pinnedPost: payload.postId } : p))
            state.project.currentProject?._id === payload.projectId && dispatch.project.setCurrentProject({
                ...state.project.currentProject,
                pinnedPost: payload.postId
            })
            dispatch.project.getPinnedPost({ projectId: payload.projectId })
        },
        unpinPost: async (payload: { projectId: string, postId: string }, state: GlobalState): Promise<void> => {
            await projectService.unpinPost(payload.projectId)
            dispatch.project.setProjects(state.project.projects.map(p => p._id === payload.projectId ? { ...p, pinnedPost: undefined } : p))
            state.project.currentProject?._id === payload.projectId && dispatch.project.setCurrentProject({
                ...state.project.currentProject,
                pinnedPost: undefined
            })
        }
    })
});