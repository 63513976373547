import { CircularProgress, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface InfiniteScrollProps {
	fetcher: Function
	renderer: Function
	items: any[]
	hasMore: boolean
	emptyState: React.FC
	hideEndInfo?: boolean
}

const InfiniteScroll = (props: InfiniteScrollProps) => {
	const loader = React.createRef<any>()
	const { t } = useTranslation()

	React.useEffect(() => {
		let options = {
			root: null,
			rootMargin: '0px',
			threshold: 0
		}
		const observer = new IntersectionObserver((entities: any[]) => {
			entities[0].isIntersecting && props.hasMore && props.fetcher()
		}, options)
		loader.current && observer.observe(loader.current)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.hasMore])

	return (
		<>
			{props.items.length > 0 && (
				<>
					{props.items.map((item) => props.renderer(item))}
					{!props.hasMore && !props.hideEndInfo && <Typography variant='caption'>{t('basic.endOfContent')}</Typography>}
				</>
			)}
			{props.items.length === 0 && !props.hasMore && <props.emptyState />}
			<div ref={loader}>{props.hasMore ? <CircularProgress /> : <></>}</div>
		</>
	)
}

export default InfiniteScroll
