import { useMediaQuery } from '@material-ui/core'
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles'
import { SnackbarProvider } from 'notistack'
import React, { Suspense, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import Background from './assets/images/FractalKukidoBackground.svg'
import KukidoAppBar from './components/AppBar'
import KukidoDrawer from './components/Drawer'
import KukidoRedirect from './components/Redirect'
import KukidoSnackbar from './components/Snackbar'
import Spinner from './components/Spinner'
import KukidoDialog from './dialogs'
import history from './history'
import useInterval from './hooks/useInterval'
import { Dispatch, GlobalState } from './models/bootstrap'
import { Role } from './models/user'
import ProjectPage from './pages/ProjectPage'
import RouteService, { Routes } from './services/RouteService'
import createTheme from './theme'

export const drawerWidth = 270

export const documentTitle = 'Kukido'

const useStyles = makeStyles((theme) => ({
	toolbar: theme.mixins.toolbar,
	backgroundWrapper: {
		position: 'relative',
		minHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
		'&:after': {
			content: '""',
			background: `url(${Background}) no-repeat left center fixed`,
			backgroundSize: 'cover',
			opacity: 0.2,
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			position: 'absolute',
			zIndex: -1
		},
		padding: theme.spacing(2)
	},
	desktopWrapper: {
		marginLeft: drawerWidth
	}
}))

const App = () => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { currentUser, projects } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser,
		projects: state.project.projects
	}))
	const [theme] = useState<Theme>(createTheme())
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	React.useEffect(() => {
		dispatch.environment.init(history)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useInterval(() => {
		dispatch.user.getNewAccessToken()
	}, 240000)

	useInterval(() => {
		dispatch.notification.getMine()
	}, 80000)

	/**
	 * This function determines the allowed routes for the current user or if user is not logged in.
	 * If the user is not logged in we only allow the login route and anonymous routes.
	 * Otherwise we map over all routes, exclude login (because you are already logged in) and add a default redirect aswell.
	 * @returns the <Route/> or <Redirect/> components for the current user / no user
	 */
	const getAllowedRouteComponents = () => {
		if (!currentUser) {
			const anonymousRoutes = RouteService.getAnonymousRoutes().map((fullRoute) => {
				return <Route exact path={fullRoute.path} component={fullRoute.routeComponent} key={fullRoute.name} />
			})

			anonymousRoutes.push(<Route component={() => <KukidoRedirect toLogin />} key='noMatchRedirect' />)

			return anonymousRoutes
		}

		const routes = RouteService.getAll().map((route) => {
			if (route.path === Routes.login) {
				return <Route exact path={route.path} component={() => <KukidoRedirect />} key='alreadyLoggedInRedirect' />
			}

			if (route.permissions?.some((p) => p === currentUser?.role)) {
				return <Route exact path={route.path} component={route.routeComponent} key={route.name} />
			}

			return null
		})

		if (projects && projects.length > 0 && currentUser) {
			switch (currentUser.role) {
				case Role.starkmacher:
				case Role.admin: {
					projects.forEach((p) =>
						routes.push(<Route exact path={`${Routes.projects}/${p._id}`} component={() => <ProjectPage project={p} />} key={p._id} />)
					)
					break
				}
				case Role.user: {
					currentUser.projects?.forEach((userProjectId) => {
						const p = projects.find((p) => p._id === userProjectId)
						p && routes.push(<Route exact path={`${Routes.projects}/${p._id}`} component={() => <ProjectPage project={p} />} key={p._id} />)
					})
					break
				}
				default:
					console.log('User has no role.. Wat?')
			}
		}

		routes.push(<Route component={() => <KukidoRedirect />} key='noMatchRedirect' />)

		return routes
	}

	if (!currentUser && !RouteService.isOnAnonymousPage()) return <Spinner />

	const permanentDrawer = !isMobile && currentUser

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider maxSnack={5}>
				{permanentDrawer && <KukidoDrawer />}
				<div className={permanentDrawer ? classes.desktopWrapper : ''}>
					<KukidoAppBar />
					<div className={classes.toolbar}></div>
					<div className={classes.backgroundWrapper} id='backgroundWrapper'>
						<div>
							<Suspense fallback={<Spinner />}>
								<Router history={history}>
									<Switch>{getAllowedRouteComponents()}</Switch>
								</Router>
							</Suspense>
						</div>
					</div>
				</div>
				<KukidoDialog />
				<KukidoSnackbar />
			</SnackbarProvider>
		</ThemeProvider>
	)
}

export default App
