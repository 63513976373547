import { useMediaQuery, Badge } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { NotificationsActiveOutlined } from '@material-ui/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { drawerWidth } from '../App'
import { Dispatch, GlobalState } from '../models/bootstrap'
import RouteService, { Routes } from '../services/RouteService'
import KukidoAvatar from './Avatar'
import KukidoDrawer from './Drawer'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		position: 'fixed',
		zIndex: 2
	},
	title: {
		flexGrow: 1
	},
	appBar: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth
	},
	ns: {
		width: theme.spacing(4),
		height: theme.spacing(4),
		cursor: 'pointer',
		marginRight: theme.spacing(3),
		display: 'flex',
		alignItems: 'end'
	}
}))

const KukidoAppBar = () => {
	const classes = useStyles()
	const dispatch = useDispatch<Dispatch>()
	const { t } = useTranslation()
	const { currentUser, currentRoute, ns } = useSelector((state: GlobalState) => ({
		currentUser: state.user.currentUser,
		currentRoute: state.environment.currentRoute,
		ns: state.notification.notifications
	}))
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const handleMenu = (event: any) => dispatch.environment.navigate(RouteService.getByPath(Routes.profile))
	const handleNotifications = () => dispatch.environment.navigate(RouteService.getByPath(Routes.notifications))

	const permanentDrawer = !isMobile && currentUser

	return (
		<div className={classes.root}>
			<AppBar position='fixed' className={permanentDrawer ? classes.appBar : ''} color='inherit'>
				<Toolbar>
					{isMobile && currentUser && (
						<>
							<KukidoDrawer />
						</>
					)}
					<Typography variant='h6' className={classes.title}>
						{currentRoute && t(`pages.${currentRoute.name}`, currentRoute.name)}
					</Typography>
					{ns && ns.length > 0 && (
						<div className={classes.ns} onClick={handleNotifications}>
							<Badge badgeContent={ns.length} max={99} color='primary'>
								<NotificationsActiveOutlined fontSize='large' />
							</Badge>
						</div>
					)}
					{currentUser && (
						<div>
							<KukidoAvatar user={currentUser} onClick={handleMenu} pulse />
						</div>
					)}
				</Toolbar>
			</AppBar>
		</div>
	)
}

export default KukidoAppBar
