import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { mergeAndSort } from '../helpers/mergeAndSort';
import ChatService from '../services/ChatService';
import { Dispatch, GlobalState } from './bootstrap';
import { KukidoAttachment } from './project';

const chatService = new ChatService();

export interface ChatMessage {
    from: string;
    to: string;
    content: string;
    deleted: boolean;
    createdAt: string;
    files?: KukidoAttachment[];
    _id: string;
}

export interface AvailableChat { userId: string, latestMessage: ChatMessage }

type ChatState = {
    availableChats: Array<AvailableChat>,
    chatPageLoading: boolean;
    chatByUserId: { [userId: string]: { messages: Array<ChatMessage>, hasMore: boolean } }
}

export const chat = createModel<RootModel>()({
    state: {
        availableChats: [],
        chatPageLoading: false,
        chatByUserId: {},
    } as ChatState,
    reducers: {
        setAvailableChats: (state: ChatState, availableChats: Array<AvailableChat>) => ({
            ...state,
            availableChats
        }),
        setChatPageLoading: (state: ChatState, chatPageLoading: boolean) => ({
            ...state,
            chatPageLoading
        }),
        setChatByUserId: (state: ChatState, payload: { userId: string, messages: Array<ChatMessage>, hasMore: boolean }) => ({
            ...state,
            chatByUserId: {
                ...state.chatByUserId,
                [payload.userId]: { messages: payload.messages, hasMore: payload.hasMore }
            }
        })
    },
    effects: (dispatch: Dispatch) => ({
        initChatPage: async (payload: void, state: GlobalState): Promise<void> => {
            dispatch.chat.setChatPageLoading(true)
            await dispatch.user.getAllUsers()
            await dispatch.chat.getAvailableChats()
            dispatch.chat.setChatPageLoading(false)
        },
        getAvailableChats: async (payload: void, state: GlobalState): Promise<void> => {
            const chats = await chatService.availableChats()
            dispatch.chat.setAvailableChats(chats)
        },
        sendChatMessage: async (payload: { to: string, content: string, files?: File[] }, state: GlobalState): Promise<void> => {
            let newFiles: KukidoAttachment[] = []
            if (payload.files) for (const file of payload.files) {
                const url = await dispatch.environment.uploadFile(file)
                newFiles.push({ name: file.name, size: file.size, url })
            }
            await chatService.sendMessage(payload.to, payload.content, newFiles)
            dispatch.chat.getAvailableChats()
            dispatch.chat.getFeedFrom({ userId: payload.to })
        },
        getFeedFrom: async (payload: { userId: string, fromStart?: boolean, checkOlder?: boolean }, state: GlobalState): Promise<void> => {
            const oldMessages = state.chat.chatByUserId?.[payload.userId]?.messages ?? []
            let requestPayload: any = { from: payload.userId }

            if (!payload.fromStart) {
                if (payload.checkOlder) {
                    if (oldMessages && oldMessages.length > 0) {
                        requestPayload.olderThan = oldMessages[oldMessages.length - 1].createdAt
                    }
                } else {
                    if (oldMessages && oldMessages.length > 0) requestPayload.newerThan = oldMessages[0].createdAt
                }
            }
            
            const messages = await chatService.getFeedFrom(requestPayload)

            const hasMore = !(messages?.length < 10)
            const newMessages = mergeAndSort(oldMessages, messages)
            dispatch.chat.setChatByUserId({ userId: payload.userId, messages: payload.fromStart ? messages : newMessages, hasMore: hasMore })
        },
        deleteChatMessage: async (payload: { id: string, withUserId: string }, state: GlobalState): Promise<void> => {
            await chatService.deleteMessage(payload.id)
            dispatch.chat.getAvailableChats()
            dispatch.chat.getFeedFrom({ userId: payload.withUserId })
        },
    })
});