import { KukidoAttachment } from "../models/project";

export const downloadFile = async (file: KukidoAttachment) => {
    const response = await fetch(file.url, { mode: 'no-cors' })
    const blob = await response.blob()
    const blobUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = file.name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
    a.remove();
}