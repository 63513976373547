interface MergeAndSortable {
    _id: string;
    createdAt: string;
    [x: string]: any;
}

/**
 * This looks complicated. It does: 
 * 1. Concat the originalState and newState.
 * 2. Reduce them with logic: 
 *  2.1 If accumulator does not have the current newly acquired entry, return accumulator + new entry.
 *  2.2 If accumulator does already have the current newly acquired entry, return the accumulator but map over it to replace the old entry with the new one.
 * 3. Sorts them by created date.
 * 
 * Use cases e.g.: You have new stories / projects that need to be merged into your state.
 */
export const mergeAndSort = <T extends MergeAndSortable> (originalState: Array<T>, newState: Array<T>) => {
    let originalStateOrEmptyArray = originalState ?? [];
    return originalStateOrEmptyArray.concat(newState).reduce((acc: Array<T>, current: T) => {
        return !acc.some(accEntry => current._id === accEntry._id) ? [...acc, current] : acc.map(ae => ae._id === current._id ? current : ae)
    }, []).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
}